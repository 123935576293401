import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Rego', 'rego', true, 'asc', 'left'),
  new TableColumn('Make & Model', 'make,model', true, 'asc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'asc', 'left'),
  new TableColumn('Built', 'buildYear,buildMonth', true, 'asc', 'left'),
  new TableColumn('Body Style', 'bodyStyle', true, 'asc', 'left'),
  new TableColumn('Pt Grp', 'paintGroup', true, 'asc', 'centered'),
  new TableColumn('Trans Type', 'transmissionType', true, 'asc', 'centered'),
  new TableColumn('Active', 'active', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export default Columns
