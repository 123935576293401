/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.VehicleFilter = (function() {

    /**
     * Namespace VehicleFilter.
     * @exports VehicleFilter
     * @namespace
     */
    var VehicleFilter = {};

    VehicleFilter.VehicleFilter = (function() {

        /**
         * Properties of a VehicleFilter.
         * @memberof VehicleFilter
         * @interface IVehicleFilter
         * @property {string|null} [rego] VehicleFilter rego
         * @property {string|null} [make] VehicleFilter make
         * @property {string|null} [model] VehicleFilter model
         * @property {string|null} [customer] VehicleFilter customer
         * @property {number|null} [active] VehicleFilter active
         * @property {string|null} [sortColumn] VehicleFilter sortColumn
         * @property {string|null} [sortOrder] VehicleFilter sortOrder
         * @property {number|null} [pageIndex] VehicleFilter pageIndex
         * @property {number|null} [pageSize] VehicleFilter pageSize
         */

        /**
         * Constructs a new VehicleFilter.
         * @memberof VehicleFilter
         * @classdesc Represents a VehicleFilter.
         * @implements IVehicleFilter
         * @constructor
         * @param {VehicleFilter.IVehicleFilter=} [p] Properties to set
         */
        function VehicleFilter(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * VehicleFilter rego.
         * @member {string|null|undefined} rego
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.rego = null;

        /**
         * VehicleFilter make.
         * @member {string|null|undefined} make
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.make = null;

        /**
         * VehicleFilter model.
         * @member {string|null|undefined} model
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.model = null;

        /**
         * VehicleFilter customer.
         * @member {string|null|undefined} customer
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.customer = null;

        /**
         * VehicleFilter active.
         * @member {number|null|undefined} active
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.active = null;

        /**
         * VehicleFilter sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.sortColumn = null;

        /**
         * VehicleFilter sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.sortOrder = null;

        /**
         * VehicleFilter pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.pageIndex = null;

        /**
         * VehicleFilter pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        VehicleFilter.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * VehicleFilter _rego.
         * @member {"rego"|undefined} _rego
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_rego", {
            get: $util.oneOfGetter($oneOfFields = ["rego"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _make.
         * @member {"make"|undefined} _make
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_make", {
            get: $util.oneOfGetter($oneOfFields = ["make"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _model.
         * @member {"model"|undefined} _model
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_model", {
            get: $util.oneOfGetter($oneOfFields = ["model"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _customer.
         * @member {"customer"|undefined} _customer
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_customer", {
            get: $util.oneOfGetter($oneOfFields = ["customer"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _active.
         * @member {"active"|undefined} _active
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_active", {
            get: $util.oneOfGetter($oneOfFields = ["active"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * VehicleFilter _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof VehicleFilter.VehicleFilter
         * @instance
         */
        Object.defineProperty(VehicleFilter.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified VehicleFilter message. Does not implicitly {@link VehicleFilter.VehicleFilter.verify|verify} messages.
         * @function encode
         * @memberof VehicleFilter.VehicleFilter
         * @static
         * @param {VehicleFilter.IVehicleFilter} m VehicleFilter message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VehicleFilter.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                w.uint32(10).string(m.rego);
            if (m.make != null && Object.hasOwnProperty.call(m, "make"))
                w.uint32(18).string(m.make);
            if (m.model != null && Object.hasOwnProperty.call(m, "model"))
                w.uint32(26).string(m.model);
            if (m.customer != null && Object.hasOwnProperty.call(m, "customer"))
                w.uint32(34).string(m.customer);
            if (m.active != null && Object.hasOwnProperty.call(m, "active"))
                w.uint32(40).int32(m.active);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(50).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(58).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(64).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(72).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified VehicleFilter message, length delimited. Does not implicitly {@link VehicleFilter.VehicleFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof VehicleFilter.VehicleFilter
         * @static
         * @param {VehicleFilter.IVehicleFilter} message VehicleFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        VehicleFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a VehicleFilter message from the specified reader or buffer.
         * @function decode
         * @memberof VehicleFilter.VehicleFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {VehicleFilter.VehicleFilter} VehicleFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VehicleFilter.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.VehicleFilter.VehicleFilter();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.rego = r.string();
                    break;
                case 2:
                    m.make = r.string();
                    break;
                case 3:
                    m.model = r.string();
                    break;
                case 4:
                    m.customer = r.string();
                    break;
                case 5:
                    m.active = r.int32();
                    break;
                case 6:
                    m.sortColumn = r.string();
                    break;
                case 7:
                    m.sortOrder = r.string();
                    break;
                case 8:
                    m.pageIndex = r.int32();
                    break;
                case 9:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a VehicleFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof VehicleFilter.VehicleFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {VehicleFilter.VehicleFilter} VehicleFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        VehicleFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return VehicleFilter;
    })();

    return VehicleFilter;
})();

module.exports = $root;
